import React, { Component } from "react";
import PropTypes from "prop-types";
import uuid from "uuid";
import { connect } from "react-redux";

import { getModalInstanceById } from "../../../utils/MaterialFunctions";
import { getGlobalVariables } from "../../../actions/globalActons";
import {
  getNumberFormatted,
  APP_ICON,
  APP_NAME,
} from "../../../utils/stringUtils";
import { printQuotation } from "../../../utils/printPdf";
import { getCurrentDateToInput } from "../../../utils/dateFormat";
import getFilesFromInput from "../../../utils/getFilesFromInput";

import TextInputField from "../../common/TextInputField";
import { autosizeTextAreaHeight } from "../../../utils/documentUtils";

let state_updating_height = false;
let state_update_components = true;

class PrintQuotation extends Component {
  state = {
    url_logo: "",
    empresa_rtn: "",
    empresa_nombre: "",
    empresa_ubicacion: "",
    empresa_telefono: "",
    empresa_email: "",
    cliente_nombre: "",
    cliente_rtn: "",
    is_printing: false,
    is_editing: true,
  };

  client_setted = false;

  componentDidMount() {
    this.setState({
      url_logo: APP_ICON,
      empresa_nombre: APP_NAME,
    });
    this.props.getGlobalVariables();
    state_update_components = true;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.global && !nextProps.global.loading) {
      const { currentLocal } = this.props.user;
      let {
        empresa_rtn,
        empresa_email,
        empresa_telefono,
        empresa_nombre,
      } = this.state;

      if (currentLocal.correo) {
        empresa_email = currentLocal.correo;
      } else if (nextProps.global.values.correo) {
        empresa_email = nextProps.global.values.correo;
      }

      if (currentLocal.codigo === "002" && currentLocal.nombre) {
        empresa_nombre = currentLocal.nombre;
      }

      if (currentLocal.rtn) {
        empresa_rtn = currentLocal.rtn;
      } else if (nextProps.global.values.rtn) {
        empresa_rtn = nextProps.global.values.rtn;
      }

      if (currentLocal.telefono) {
        empresa_telefono = currentLocal.telefono;
      }
      this.setState({
        empresa_rtn,
        empresa_email,
        empresa_telefono,
        empresa_nombre,
      });
    }
    if (nextProps.cotizacion && nextProps.cotizacion.cliente) {
      let { nombre, rtn } = nextProps.cotizacion.cliente;
      if (!nombre) nombre = "";
      if (!rtn) rtn = "";
      this.setState({
        cliente_nombre: nombre,
        cliente_rtn: rtn,
      });
    }

    if (nextProps.cotizacion && nextProps.cotizacion.local) {
      const { ubicacion } = nextProps.cotizacion.local;
      if (ubicacion) {
        this.setState({ empresa_ubicacion: ubicacion });
      }
    }
  }

  shouldComponentUpdate(nextProps, nextStat) {
    return state_update_components;
  }

  componentDidUpdate() {
    if (this.state.is_printing) {
      this.setState({
        is_printing: false,
      });
      printQuotation("div_print_cotizacion", () => {
        this.setState({
          is_editing: true,
        });
        this.onCancelPrint();
      });
    }

    if (
      this.props.cotizacion.productos &&
      this.props.cotizacion.productos.productos &&
      this.props.cotizacion.productos.productos.length > 0 &&
      !state_updating_height
    ) {
      state_updating_height = true;
      setTimeout(() => {
        state_update_components = false;
        this.adjustAllItems();
        state_updating_height = false;
      }, 1000 * 5);
    }
  }

  onChangeTextInput = (e) => {
    state_update_components = true;
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeFiles = (e) => {
    getFilesFromInput(e, (files) => {
      this.setState({ files });
    });
  };

  hideModal = () => {
    getModalInstanceById("modal_imprimir_cotizacion").close();
  };

  onCancelPrint = () => {
    this.hideModal();
    this.props.onCancel();
  };

  onPrintClick = () => {
    this.adjustAllItems();
    state_update_components = true;
    this.setState({
      is_editing: false,
      is_printing: true,
    });
  };

  adjustAllItems = () => {
    if (
      this.props.cotizacion.productos &&
      this.props.cotizacion.productos.productos
    ) {
      const { productos } = this.props.cotizacion.productos;
      if (productos.length > 0) {
        state_update_components = false;
        productos.forEach((p) =>
          this.adjustHeight(`txt_area${p.id || p.id_producto}`, 20)
        );
      }
    }
  };

  adjustHeight = (
    id_element,
    minHeight,
    save_value = false,
    update_components = false
  ) => {
    const textArea = document.getElementById(id_element);
    if (textArea) {
      autosizeTextAreaHeight(id_element, minHeight);
      const id_producto = id_element.replace("txt_area", "");
      if (save_value) {
        const productoIndex = this.props.cotizacion.productos.productos.findIndex(
          (p) => p.id === id_producto || p.id_producto === id_producto
        );
        if (productoIndex >= 0) {
          this.props.cotizacion.productos.productos[
            productoIndex
          ].nombre = textArea.value.trim();
        }
      }
      if (update_components) {
        state_update_components = true;
      }
    }
  };

  getQuoteData = () => {
    let {
      cotizacion: { productos, values, id },
    } = this.props;
    const {
      cliente_nombre,
      cliente_rtn,
      empresa_nombre,
      empresa_ubicacion,
      empresa_telefono,
      empresa_email,
      empresa_rtn,
      is_editing,
    } = this.state;
    let clientContent, productsContent, localContent;

    if (!id) {
      id = Math.floor(Math.random() * (2000 - 600 + 1) + 600);
    }

    clientContent = (
      <div>
        {id && <span className="bold">Cotizacion #{id}</span>}
        <span className="d-block">Cotizado a: {cliente_nombre}</span>
        <span className="print print-rtn-date">
          <span className="print-rtn">RTN: {cliente_rtn}</span>
          <span className="print-date">Fecha: {getCurrentDateToInput()}</span>
        </span>
      </div>
    );
    if (productos.productos) {
      productsContent = productos.productos.map((producto) => (
        <tr key={uuid()}>
          <td>
            {is_editing ? (
              <textarea
                className="browser-default input-row-transparent overflowy-hidden"
                defaultValue={producto.nombre}
                id={`txt_area${producto.id || producto.id_producto}`}
                onInput={this.adjustHeight.bind(
                  this,
                  `txt_area${producto.id || producto.id_producto}`,
                  20,
                  true,
                  true
                )}
              />
            ) : (
              <span>{producto.nombre}</span>
            )}
          </td>
          <td>{producto.cantidad}</td>
          <td>{getNumberFormatted(producto.precio)}</td>
          <td className="text-align-right">
            {getNumberFormatted(producto.cantidad * producto.precio)}
          </td>
        </tr>
      ));
    }

    localContent = (
      <div className="row">
        <div className="cotizacion_header mt-1">
          <span className="d-block">{empresa_nombre}</span>
          <span className="d-block">{empresa_ubicacion}</span>
          <span className="d-block">TEL: {empresa_telefono}</span>
          <span className="d-block">E-mail: {empresa_email}</span>
          <span className="d-block">RTN: {empresa_rtn}</span>
        </div>
      </div>
    );

    return (
      <div id="div_print_cotizacion" className="div_reporte">
        {localContent}
        {clientContent}
        <table className="table-stripped table-bordered mt-1">
          <tbody>
            <tr>
              <th>PRODUCTO</th>
              <th style={{ maxWidth: "80px", minWidth: "70px" }}>CANTIDAD</th>
              <th style={{ maxWidth: "90px" }}>PRECIO UNIT.</th>
              <th style={{ maxWidth: "80px" }}>SUBTOTAL. LPS</th>
            </tr>
            {productsContent}
          </tbody>
        </table>
        <div className="w-100 cotizacion-footer">
          <span className="d-block">
            Sub total: Lps {getNumberFormatted(values.subtotal)}
          </span>
          <span className="d-block">
            Impuesto: Lps {getNumberFormatted(values.impuesto)}
          </span>
          <span className="d-block">
            Total: Lps {getNumberFormatted(values.total)}
          </span>
        </div>
      </div>
    );
  };

  render() {
    let quoteData = this.getQuoteData();
    return (
      <div
        className="modal no-padding modal-big modal-cotizacion"
        id="modal_imprimir_cotizacion"
        style={{
          width: "800px",
        }}
      >
        <div className="modal-content no-padding">
          <div
            className="card sticky-action"
            style={{
              width: "100%",
              margin: "0",
            }}
          >
            <div
              className="card-content"
              style={{
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              <div className="div_reporte">{quoteData}</div>
            </div>
            <div
              className="card-action"
              style={{ marginBottom: "0", height: "50px" }}
            >
              <a
                href="#!"
                className="btn-flat left"
                onClick={this.onCancelPrint}
              >
                Cerrar
              </a>

              <a href="#!" className="activator btn-flat">
                Editar informacion
              </a>

              <a href="#!" className="btn-flat" onClick={this.adjustAllItems}>
                Actualizar vista
              </a>

              <a href="#!" className="btn" onClick={this.onPrintClick}>
                Imprimir
              </a>
            </div>
            <div className="card-reveal">
              <span className="card-title grey-text text-darken-4">
                Editar informacion<i className="material-icons right">close</i>
              </span>

              <span className="d-block mt-1">Informacion de la empresa</span>

              <div className="row">
                <TextInputField
                  id="empresa_nombre"
                  label="Nombre de empresa"
                  onchange={this.onChangeTextInput}
                  value={this.state.empresa_nombre}
                />
              </div>

              <div className="row">
                <TextInputField
                  id="empresa_ubicacion"
                  label="Ubicacion"
                  onchange={this.onChangeTextInput}
                  value={this.state.empresa_ubicacion}
                />
              </div>

              <div className="row">
                <TextInputField
                  id="empresa_telefono"
                  label="Telefono"
                  onchange={this.onChangeTextInput}
                  value={this.state.empresa_telefono}
                />
              </div>

              <div className="row">
                <TextInputField
                  id="empresa_email"
                  type="email"
                  label="Email"
                  onchange={this.onChangeTextInput}
                  value={this.state.empresa_email}
                />
              </div>

              <div className="row">
                <TextInputField
                  id="empresa_rtn"
                  label="RTN"
                  onchange={this.onChangeTextInput}
                  value={this.state.empresa_rtn}
                />
              </div>

              <span className="d-block mt-1">Informacion del cliente</span>
              <div className="row">
                <TextInputField
                  id="cliente_nombre"
                  label="Nombre del cliente"
                  onchange={this.onChangeTextInput}
                  value={this.state.cliente_nombre}
                />
              </div>

              <div className="row">
                <TextInputField
                  id="cliente_rtn"
                  label="RTN del cliente"
                  onchange={this.onChangeTextInput}
                  value={this.state.cliente_rtn}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PrintQuotation.propTypes = {
  user: PropTypes.object.isRequired,
  cotizacion: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  getGlobalVariables: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  global: state.global,
  user: state.user,
});

export default connect(mapStateToProps, { getGlobalVariables })(PrintQuotation);
