export const API_URL = "https://minimarkethn.com/api";
export const APP_ICON = "https://minimarkethn.com/files/icons/site_icon.png";
export const APP_NAME = "Minimarket";

export const getNumberFormatted = (number) => {
  number = Number(number).toFixed(2);
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const fullTrim = (str_value) => {
  return `${str_value}`
    .replace(/(?:(?:^|\n)\s+|\s+(?:$|\n))/g, "")
    .replace(/\s+/g, " ");
};
