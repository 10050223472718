import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import NavbarAdmin from "../../../layout/NewNavbarAdmin";
import uuid from "uuid";

import {
  configMaterialComponents,
  removeMaterialComponents,
} from "../../../../utils/MaterialFunctions";

import {
  getProductsToExport,
  getProductsToExportWithParams,
  cleanProductsReducer,
} from "../../../../actions/productActions";
import { jsonToExcel } from "../../../../utils/jsonExcel";

import CheckInputField from "../../../common/CheckInputField";
import InputField from "../../../common/TextInputField";
import Spinner from "../../../common/Spinner";
import ProductFromExcelCard from "../../../common/ProductsImportCard";
import { APP_NAME } from "../../../../utils/stringUtils";

class ExportProductExcel extends Component {
  state = {
    show_products: false,
    vista_detallada: true,
    cantidad_minima: -1,
    cantidad_maxima: -1,
  };

  products_are_simple = false;

  componentWillMount() {
    removeMaterialComponents();
  }

  componentDidMount() {
    configMaterialComponents();
  }

  componentWillUnmount() {
    this.props.cleanProductsReducer();
  }

  onChangeCheckField = (e) => {
    const current_value = this.state[e.target.name];
    this.setState({ [e.target.name]: !current_value });
  };

  onChangeTextInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onGetProductsClick = () => {
    const { cantidad_minima, cantidad_maxima, vista_detallada } = this.state;
    this.props.cleanProductsReducer();
    if (vista_detallada) {
      this.products_are_simple = false;
      return this.props.getProductsToExport();
    }
    this.products_are_simple = true;
    this.setState({
      show_products: true,
    });
    this.props.getProductsToExportWithParams({
      cantidad_minima,
      cantidad_maxima,
      vista_detallada,
    });
  };

  getStrProductStatus = (p_estatus, cantidad = null) => {
    if (cantidad && p_estatus !== "ST_ORDERED") {
      cantidad = parseInt(cantidad);
      if (cantidad === 0) {
        return "Inactivo";
      } else if (cantidad > 0) {
        return "Activo";
      }
    }
    let estatus = "Activo";
    switch (p_estatus) {
      case "ST_INACTIVE":
        estatus = "Inactivo";
        break;

      case "ST_ORDERED":
        estatus = "Ordenado";
        break;
      default:
        break;
    }
    return estatus;
  };

  onExportToExcel = () => {
    const date = new Date();
    const date_str_name = `${date.getFullYear()}_${
      date.getMonth() + 1
    }_${date.getDate()} ${date.getHours()}:${date.getSeconds()}`;

    let file_name = `${APP_NAME}_productos`;

    let headers;
    let productosExport;
    if (this.products_are_simple) {
      const data = this.getSimpleExportProducts();
      headers = data.headers;
      productosExport = data.productosExport;
      file_name = `${file_name} desde ${this.state.cantidad_minima} hasta ${this.state.cantidad_maxima}`;
    } else {
      const data = this.getAdvancedExportProducts();
      headers = data.headers;
      productosExport = data.productosExport;

      file_name = `${file_name} ${date_str_name}`;
    }

    jsonToExcel(productosExport, headers, file_name);
  };

  getSimpleExportProducts = () => {
    let headers = {
      codigo: "codigo",
      descripcion: "descripcion",
      cantidad: "cantidad",
      precio: "precio",
      estatus: "estatus",
      total_pedido: "total_pedido",
    };
    const productosExport = [];
    const { products } = this.props.product;

    products.forEach((prod) => {
      productosExport.push({
        codigo: `${prod.codigo}`,
        descripcion: prod.descripcion,
        cantidad: `${prod.cantidad}`,
        precio: `${prod.precio}`,
        estatus: this.getStrProductStatus(prod.status),
        total_pedido: prod.total_pedido ? `${prod.total_pedido}` : "",
      });
    });
    return {
      headers,
      productosExport,
    };
  };

  getAdvancedExportProducts = () => {
    let headers = {
      codigo: "codigo",
      descripcion: "descripcion",
      cantidad: "cantidad",
      cantidad_minima: "cantidad_minima",
      precio: "precio",
      local: "local",
      cantidad_minima_local: "cantidad_minima_local",
      ubicacion: "ubicacion",
      estatus: "estatus",
      total_pedido: "total_pedido",
    };
    const productosExport = [];
    const { products } = this.props.product;

    products.forEach((prod) => {
      if (prod.distribucion && prod.distribucion.length > 0) {
        prod.distribucion.forEach((dist) => {
          productosExport.push({
            codigo: `${prod.codigo}`,
            descripcion: prod.descripcion,
            cantidad: `${dist.cantidad}`,
            cantidad_minima: `${prod.cantidad_minima}`,
            precio: prod.precio,
            local: `${dist.local}`,
            cantidad_minima_local: `${dist.cantidad_minima_local}`,
            ubicacion: dist.ubicacion,
            estatus: this.getStrProductStatus(prod.status, dist.cantidad),
            total_pedido: prod.total_pedido ? `${prod.total_pedido}` : "",
          });
        });
      } else {
        productosExport.push({
          codigo: `${prod.codigo}`,
          descripcion: prod.descripcion,
          cantidad: `${prod.cantidad}`,
          cantidad_minima: `${prod.cantidad_minima}`,
          precio: `${prod.precio}`,
          local: "",
          cantidad_minima_local: "",
          ubicacion: "",
          estatus: this.getStrProductStatus(prod.status),
          total_pedido: prod.total_pedido ? `${prod.total_pedido}` : "",
        });
      }
    });
    return {
      headers,
      productosExport,
    };
  };

  getProductsCards = (products) => {
    let products_content;

    if (!this.products_are_simple) {
      products_content = products.map((prod) => (
        <ProductFromExcelCard key={uuid()} producto={prod} />
      ));
    } else {
      products_content = (
        <h6>Click en "Obtener productos" para cargar la informacion</h6>
      );
    }
    return (
      <div className="card">
        <div className="card-content">{products_content}</div>
      </div>
    );
  };

  getProductsTable = (products) => {
    return (
      <div className="card">
        <div className="card-content">
          <table className="table striped">
            <thead>
              <tr>
                <th>Codigo</th>
                <th>Descripcion</th>
                <th>Precio</th>
                <th>Cantidad</th>
                <th>Estatus</th>
                <th>Pedidos</th>
              </tr>
            </thead>
            <tbody>
              {products.map((p) => (
                <tr key={uuid()}>
                  <td>{p.codigo}</td>
                  <td>{p.descripcion}</td>
                  <td>{p.precio}</td>
                  <td>{p.cantidad}</td>
                  <td>{this.getStrProductStatus(p.status)}</td>
                  <td>{p.total_pedido ? p.total_pedido : "0"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  render() {
    const { loading, products } = this.props.product;
    const {
      show_products,
      cantidad_minima,
      cantidad_maxima,
      vista_detallada,
    } = this.state;

    let products_content;

    if (show_products) {
      if (vista_detallada) {
        products_content = this.getProductsCards(products);
      } else {
        products_content = this.getProductsTable(products);
      }
    }
    return (
      <React.Fragment>
        <NavbarAdmin>
          <div className="nav-wrapper">
            <a href="#!" className="brand-logo">
              Exportar productos
            </a>
            <a href="#!" className="sidenav-trigger" data-target="nav_sidenav">
              <i className="material-icons">menu</i>
            </a>
          </div>
        </NavbarAdmin>

        <main>
          <div className="row">
            <div className="col s12">
              <div className="card">
                <div className="card-content">
                  <h6>Productos totales: {products.length}</h6>
                  <div className="row">
                    <CheckInputField
                      id="show_products"
                      checked={show_products}
                      label="Mostrar productos"
                      size="s12 m6"
                      onchange={this.onChangeCheckField}
                    />

                    <CheckInputField
                      id="vista_detallada"
                      checked={vista_detallada}
                      label="Informacion completa de multiples locales"
                      size="s12 m6"
                      onchange={this.onChangeCheckField}
                    />
                  </div>
                  <div className="row">
                    <InputField
                      id="cantidad_minima"
                      type="number"
                      value={cantidad_minima}
                      label="Cantidad minima"
                      input_size="s12 m6"
                      onchange={this.onChangeTextInput}
                    />

                    <InputField
                      id="cantidad_maxima"
                      type="number"
                      value={cantidad_maxima}
                      label="Cantidad maxima"
                      input_size="s12 m6"
                      onchange={this.onChangeTextInput}
                    />
                  </div>
                  <button className="btn" onClick={this.onGetProductsClick}>
                    Obtener productos
                  </button>
                  <button className="btn ml-1" onClick={this.onExportToExcel}>
                    Exportar como Excel
                  </button>

                  {loading && <Spinner fullWidth />}
                </div>
              </div>

              {products_content}
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

ExportProductExcel.propTypes = {
  product: PropTypes.object.isRequired,
  getProductsToExport: PropTypes.func.isRequired,
  cleanProductsReducer: PropTypes.func.isRequired,
  getProductsToExportWithParams: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  product: state.product,
});

export default connect(mapStateToProps, {
  getProductsToExport,
  cleanProductsReducer,
  getProductsToExportWithParams,
})(ExportProductExcel);
