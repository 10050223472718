// import isEmpty from "../actions/isEmpty"
import XLSX from "xlsx";

export const getJsonFromExcel = (
  excelFile,
  callback,
  config = { sheet: 0 }
) => {
  if (excelFile === null) {
    return callback([]);
  }

  var reader = new FileReader();
  let newJson = [];

  try {
    reader.onload = function (e) {
      try {
        var data = e.target.result;
        var excel = XLSX.read(data, { type: "binary" });

        if (config.sheet !== -1) {
          const sheetName = excel.SheetNames[config.sheet];
          const jsonRows = getJsonFromSheet(excel, sheetName);
          jsonRows.forEach((row) => newJson.push(row));
        } else {
          excel.SheetNames.forEach(function (sheetName) {
            const jsonRows = getJsonFromSheet(excel, sheetName);
            jsonRows.forEach((row) => newJson.push(row));
          });
        }
        callback(newJson);
      } catch (e) {
        console.log(e);
        callback(newJson);
      }
    };
    reader.readAsBinaryString(excelFile);
  } catch (e) {
    console.log(e);
    callback([]);
  }
};

function getJsonFromSheet(excel, sheetName) {
  const sheetRows = XLSX.utils.sheet_to_json(excel.Sheets[sheetName], {
    blankrows: false,
  });
  return sheetRows;
  // const newJson = []
  // if (sheetRows.length > 0) {
  //   const headers = sheetRows[0]

  //   for (let index = 1; index < sheetRows.length; index++) {
  //     const json_row = sheetRows[index]

  //     if (!headers.__EMPTY) {
  //       newJson.push(json_row)
  //       continue
  //     }
  //     let new_json_row = {}
  //     for (var key in headers) {
  //       if (typeof json_row[key] !== "undefined") {
  //         new_json_row[headers[key]] = json_row[key]
  //       }
  //     }
  //     if (!isEmpty(new_json_row)) {
  //       newJson.push(new_json_row)
  //     }
  //   }
  // }
  // return newJson
}

export const jsonToExcel = (
  jsonArray,
  headers,
  fileName,
  deleteNotFoundKeys = false
) => {
  // format the data
  const itemsFormatted = [];
  const headerKeys = Object.keys(headers);

  jsonArray.forEach((item) => {
    for (var key in item) {
      if (headerKeys.length > 0 && deleteNotFoundKeys) {
        const headerIndex = headerKeys.indexOf(key);
        if (headerIndex < 0) {
          delete item[key];
          continue;
        }
      }

      if (typeof item[key] === "undefined" || item[key] === null) continue;
      if (typeof item[key] !== "string") {
        item[key] = `${item[key]}`;
      }
      item[key] = removeCommas(item[key]);
      item[key] = removeWhiteLines(item[key]);
    }
    itemsFormatted.push(item);
  });

  const sheet = XLSX.utils.json_to_sheet(jsonArray);
  const book = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(book, sheet, "Hoja1");

  XLSX.writeFile(book, `${fileName}.xlsx`);
};

function removeCommas(value) {
  return value.replace(/,/g, "");
}

function removeWhiteLines(value) {
  return value.replace(/(\r\n|\n|\r)/g, "");
}

function exportCSVFile(headers, items, fileTitle) {
  if (headers) {
    items.unshift(headers);
  }

  // Convert Object to JSON
  var jsonObject = JSON.stringify(items);
  var csv = convertToCSV(jsonObject);
  var exportedFilenmae = fileTitle + ".csv" || "export.csv";
  var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

function convertToCSV(objArray) {
  var array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
  var str = "";

  for (var i = 0; i < array.length; i++) {
    var line = "";
    for (var index in array[i]) {
      if (line !== "") line += ",";

      line += array[i][index];
    }

    str += line + "\r\n";
  }

  return str;
}
