import React, { Component } from "react";
import { Link } from "react-router-dom";

import {
  configMaterialComponents,
  removeMaterialComponents,
} from "../../../utils/MaterialFunctions";

import { APP_NAME, APP_ICON } from "../../../utils/stringUtils";

class Home extends Component {
  componentWillMount() {
    removeMaterialComponents();
  }

  componentDidMount() {
    configMaterialComponents();
  }

  render() {
    return (
      <React.Fragment>
        <header className="normal-site">
          <nav className="red lighten-2">
            <div className="container">
              <div className="nav-wrapper">
                <a href="" className="brand-logo">
                  {APP_NAME}
                </a>
                <a
                  href="#!"
                  className="sidenav-trigger"
                  data-target="nav_sidenav"
                >
                  <i className="material-icons">menu</i>
                </a>
                <ul className="right hide-on-small-only">
                  <li>
                    <Link to="/login">Login</Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

          <ul className="sidenav" id="nav_sidenav">
            <li>
              <Link to="/login">Login</Link>
            </li>
          </ul>
        </header>
        <main className="grey lighten-5 normal-site">
          <div className="container">
            <div className="big-home-image-container">
              <img src={APP_ICON} alt="" className="big-home-image" />
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default Home;
